import { qs } from "../utils/dom"

export function initNav() {
  initToggleNav()
  initScrolledNav()
  initNavDropdown()
}

function initToggleNav() {
  const body = document.body
  const mobileMenu = qs(".mobile-menu")

  body.addEventListener("click", (e) => {
    let el
    if ((el = e.target.closest("[data-menu-toggle]"))) {
      e.preventDefault()
      _setMenuState(el.dataset.menuToggle === "open")
    }
  })

  function _setMenuState(isOpen) {
    mobileMenu.setAttribute("aria-expanded", isOpen ? "true" : "false")
    body.classList[isOpen ? "add" : "remove"]("menu-is-open")
  }

  body.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      _setMenuState(0)
    }
  })
}

function initScrolledNav() {
  const hero = qs(".hero")
  if (hero) {
    const handleScroll = () => {
      hero.classList.toggle("hero--top", window.scrollY < 16)
    }
    handleScroll()
    window.addEventListener("scroll", handleScroll, { passive: true })
  }
}

function initNavDropdown() {
  const el = qs(".nav-dropdown"),
    btn = el && qs("button", el),
    dropdown = el && qs(".nav-dropdown__dropdown", el)
  if (el && btn && dropdown) {
    btn.addEventListener("click", () => {
      dropdown.classList.toggle("hide")
    })

    document.body.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        dropdown.classList.add("hide")
      }
    })
  }
}
